// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import dataTables from '@src/views/tables/data-tables/store/reducer'

import userStore from '@src/views/apps/admin/store/reducer/AdminReducer'
import companyStore from '@src/views/apps/company/store/reducer/CompanyReducer'
import subscriptionStore from '@src/views/apps/subscription/store/reducer/SubscriptionReducer'
import ticketStore from '@src/views/apps/ticket/store/reducer/TicketReducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  dataTables,
  userStore,
  companyStore,
  subscriptionStore,
  ticketStore
})

export default rootReducer
